<template>
  <div id="stage" ref="stage" :style="{ height: width * 6 + 'px' }">
    <div v-show="grid">
      <div class="grid-container">
        <div v-for="n in 10" :key="n" class="grid-x" :style="{ width: width + 'px' }">
          {{ n }}
        </div>
        <div class="grid-container">
          <div
            v-for="n in 6"
            :key="n"
            class="grid-y"
            :style="{ height: width + 'px', lineHeight: width + 'px' }"
          >
            {{ n }}
          </div>
        </div>
      </div>
    </div>
    <template v-for="block in editList" :key="block._uid">
      <component
        :is="block.component"
        :block="block"
        :gridWidth="width"
        @command="command"
      ></component>
    </template>
  </div>
  <button class="start-button">
    <div class="icon">
      <fas icon="play" /><span style="margin-left: 0.5em">スタート</span>
    </div>
  </button>
  <button class="start-button">
    <div class="icon">
      <fas icon="stop" /><span style="margin-left: 0.5em">ストップ</span>
    </div>
  </button>
  <button class="grid-button" @click="grid = !grid">
    <div class="icon">
      <fas icon="border-all" /><span style="margin-left: 0.5em">グリッド</span>
    </div>
  </button>
</template>

<script>
import ImgComp from "@/components/ImgComponent.vue";
import StringComp from "@/components/StringComponent.vue";

export default {
  name: "Test",
  components: {
    ImgComp,
    StringComp,
  },
  computed: {
    editList: {
      get() {
        return this.$store.state.editList;
      },
      set(value) {
        this.$store.commit("updateEditList", value);
      },
    },
  },
  data() {
    return {
      width: 0,
      grid: false,
      content: {
        body: [
          {
            _uid: "BUY6Drn9e1",
            component: "ImgComp",
            img: ["image/logo.png"],
            text: "test",
            disp: 0,
            width: 1,
            x: 1,
            y: 1,
            display: 1,
            talk: "",
          },
          {
            _uid: "BUY6Drn9e2",
            component: "ImgComp",
            img: ["image/test2.png"],
            disp: 0,
            width: 1,
            x: 2,
            y: 2,
            display: 1,
            talk: "",
          },
          {
            _uid: "BUY6Drn9e3",
            component: "ImgComp",
            img: ["image/test2.png", "image/logo.png"],
            disp: 0,
            width: 1,
            x: 5,
            y: 3,
            display: 1,
            talk: "",
            click: [
              { method: "talk", arg: [20, 2, "test talk"] },
              { method: "talk", arg: [1, 2, ""] },
              { method: "display", arg: [2, 2, 0] },
              { method: "display", arg: [2, 2, 1] },
              { method: "moveUp", arg: [3, 2, 1] },
              { method: "moveDown", arg: [3, 2, 1] },
              { method: "move", arg: [3, 2, 5, 3] }, //秒、対象、x、ｙ
              {
                method: "repeat",
                arg: [410, 10],
                nest: [
                  { method: "changeImage", arg: [1, 2, 1] },
                  {
                    method: "doWhile",
                    arg: [38, 2], //nestの中の待ち時間合計×回数
                    nest: [
                      { method: "moveRight", arg: [3, 2, 1] },
                      { method: "moveLeft", arg: [3, 2, 1] },
                      {
                        method: "doWhile",
                        arg: [13, 2], //nestの中の待ち時間合計×回数
                        nest: [
                          { method: "moveUp", arg: [3, 2, 1] },
                          { method: "moveDown", arg: [3, 2, 1] },
                        ],
                      },
                    ],
                  },
                  { method: "changeImage", arg: [1, 2, 0] },
                ],
              },
              { method: "moveLeft", arg: [3, 2, 1] },
            ],
          },
        ],
      },
    };
  },
  methods: {
    getWidth: function () {
      this.width = document.getElementById("stage").clientWidth / 10;
      console.log(this.width);
    },

    sleep: function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    command: async function (click) {
      if (click) {
        for (let command of click) {
          this[command.method](command.arg, command.nest);
          await this.sleep(command.arg[0] * 100);
        }
      }
    },

    repeat: async function (arg, nest) {
      for (var i = 0; i < arg[1]; i++) {
        console.log(nest);
        for (var int = 0; int < nest.length; int++) {
          console.log(nest[int].method);
          this[nest[int].method](nest[int].arg, nest[int].nest);
          await this.sleep(nest[int].arg[0] * 100);
        }
      }
    },
    talk: function (arg) {
      console.log(arg);
      var num = arg[1];
      var text = arg[2];
      this.content.body[num].talk = text;
    },
    display: function (arg) {
      console.log("display");
      var num = arg[1];
      var int = arg[2];
      this.content.body[num].display = int;
    },
    move: function (arg) {
      console.log("moveUp");
      var num = arg[1];
      var x = arg[2];
      var y = arg[3];
      this.content.body[num].x = x;
      this.content.body[num].y = y;
    },
    moveUp: function (arg) {
      console.log("moveUp");
      var num = arg[1];
      var int = arg[2];
      var y = this.content.body[num].y;
      if (y > 0) {
        this.content.body[num].y -= int;
      }
    },
    moveDown: function (arg) {
      console.log("moveDown");
      var num = arg[1];
      var int = arg[2];
      var y = this.content.body[num].y;
      if (y > 0) {
        this.content.body[num].y += int;
      }
    },
    moveLeft: function (arg) {
      console.log("moveLeft");
      var num = arg[1];
      var int = arg[2];
      var x = this.content.body[num].x;
      if (x > 0) {
        this.content.body[num].x -= int;
      }
    },
    moveRight: function (arg) {
      console.log("moveRight");
      var num = arg[1];
      var int = arg[2];
      var x = this.content.body[num].x;
      if (x > 0) {
        this.content.body[num].x += int;
      }
    },
    changeImage: function (arg) {
      var num = arg[1];
      var int = arg[2];
      this.content.body[num].disp = int;
    },
  },
  mounted() {
    this.width = document.getElementById("stage").clientWidth / 10;
    window.addEventListener("resize", this.getWidth);
  },
};
</script>

<style scoped>
#stage {
  position: relative;
  background: white;
  border: 2px gray solid;
  overflow: hidden;
  transition: all 0.2s ease;
}
.grid-container {
  width: 100%;
  height: 100%;
  position: absolute;
}
.grid-x {
  border: 1px solid #f1f1f1;
  height: 100%;
  float: left;
  margin-left: 0px;
  color: #dddddd;
  text-align: center;
}
.grid-x:first-child {
  margin-left: -1px;
}
.grid-x:last-child {
  margin-left: -1px;
}
.grid-y {
  border: 1px solid #f1f1f1;
  color: #dddddd;
  clear: both;
}
.start-button {
  width: 30%;
  text-align: center;
  padding: 0.5em;
  margin: 0.2em 0;
  margin-right: 4%;
  border: solid 1px gray;
  border-radius: 5px;
  letter-spacing: 0.2em;
}
.grid-button {
  width: 30%;
  min-width: 80px;
  text-align: center;
  padding: 0.5em;
  margin: 0.2em 0;
  border: solid 1px gray;
  border-radius: 5px;
  letter-spacing: 0.2em;
  display: inline-block;
}
.icon {
  margin-right: 0.2em;
}
</style>
