<template>
  <div class="component" :class="block.class" @click="Command">
    <div class="icon"><fas icon="comment-dots" /></div>
    <div class="command">
      <select
        :value="block.arg[1]"
        @change="$emit('changeVal', block.id, $event.target.value, 1)"
      >
        <option v-for="nameList in nameList" :key="nameList.id" :value="nameList.id">
          {{ nameList.name }}
        </option>
      </select>
      が
      <input type="text" />
      <br />と言う
    </div>
  </div>
</template>

<script>
//{ id: '040', block: "talk", method: "talk", arg: [1, 0, ""] }, 秒、対象、吹き出しに表示する言葉
export default {
  props: {
    block: Object,
    nameList: Object,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon {
  width: 50px;
  min-height: 50px;
  background: #ffcccc;
  font-size: 30px;
  text-align: center;
}
</style>
