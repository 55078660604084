<template>
  <div
    class="component"
    :class="block.class"
    @click="Command"
  >
    <div class="icon"><fas icon="exchange-alt" /></div>
    <div class="command">
      変数を
      <input type="text" maxlength="20" />
      にする
  </div>
  </div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
  data() {
    return {
    };
  },
  methods: {
    Command(){
      this.$emit("command", this.block.click);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 95%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon{
  min-width: 50px;
  height: 50px;
  background: #64b184;
  font-size: 30px;
  text-align: center;
  color: #fff8ef;
}
</style>
