<template>
  <div class="if-block">
    <div class="icon"><fas icon="question" /></div>
    <div class="component" :class="block.class">
      <div class="command">
        もし<br />
        <input type="text" />
        なら
      </div>
    </div>

    <draggable-block-component
      :blockList="block.list"
      :nameList="nameList"
      @changeVal="changeVal"
    />

    <div class="component end" :class="block.class">
      <div class="command">おわり</div>
    </div>
  </div>
</template>

<script>
//{ id: '051', block: "if", method: "if", arg: [1, 0, ""], list: [] 
//arg:秒、対象、

import { defineAsyncComponent } from "vue";
import DraggableBlockComponent from "@/components/DraggableBlockComponent.vue";

export default {
  components: {
    DraggableBlockComponent: defineAsyncComponent(() =>
      Promise.resolve(DraggableBlockComponent)
    ),
  },
  props: {
    block: Object,
    nameList: Object
  },
  data() {
    return {};
  },
  methods: {
    changeVal: function (id, val, prop) {
      var ID;
      if(Array.isArray(id)){
        console.log("array!")
        ID = id;
      }else{
        console.log("noArray")
        ID= [id];
      }
      ID.push(this.block.id);
      console.log(ID);
      this.$emit('changeVal', ID, val, prop)
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  margin: 5px;
  display: flex;
}
.end {
  min-height: 25px;
}
.icon {
  min-width: 50px;
  width: 50px;
  height: 100%;
  background: #ffcd8b;
  outline: white solid 2px;
  font-size: 30px;
  text-align: center;
  position: absolute;
  left: 5px;
}
.command {
  margin-left: 50px;
}
.del {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -2px;
  right: -25px;
  background: #dadada;
  border: gray solid 2px;
  text-align: center;
}
.nest {
  margin-left: 25px;
}
</style>
