<template>
  <div class="relative content-height float-left bg-peach-light w-full sm:w-2/4 md:w-1/4">
    <div class="overflow-y-scroll content-height p-4">
      <div
        class="bg-white p-2 border-t-4 border-peach-default mb-2"
        v-for="List in ideaList"
        :key="List.id"
        draggable="true"
        @dragstart="dragList($event, 'memo', List.name, List.memo)"
        @touchstart="touchStartEvent($event, 'memo', List.name, List.memo)"
        @touchend="touchEndEvent($event)"
      >
        <div>{{ List.name }}</div>
        <div>{{ List.memo }}</div>
      </div>
    </div>
    <div>
      <input @change="addIdea" v-model="memo" class="memo-input" type="text" />
    </div>
  </div>
</template>

<script>
import { getDatabase, ref, push, onValue } from "firebase/database";
export default {
  name: "Memo",
  data() {
    return {
      position: "",
      memo: "",
    };
  },
  computed: {
    ideaList: {
      get() {
        return this.$store.state.ideaList;
      },
      set(value) {
        this.$store.commit("updateIdeaList", value);
      },
    },
    dragItem: {
      get() {
        return this.$store.state.dragItem;
      },
      set(value) {
        this.$store.commit("updateDragItem", value);
      },
    },
    thinkList: {
      get() {
        return this.$store.state.thinkList;
      },
      set(value) {
        this.$store.commit("updateThinkList", value);
      },
    },
    uname:{
      get() {
        return this.$store.state.uname;
      },
    }
  },
  mounted() {
    const db = getDatabase();
    const ideaRef = ref(db, "/ideaData/" + this.$route.params.id + "/");
    onValue(ideaRef, (snapshot) => {
      const ideadata = snapshot.val();
      console.log(ideadata);
      this.ideaList = ideadata;
    });
  },
  methods: {
    addIdea: function () {
      const db = getDatabase();
      push(ref(db, "ideaData/" + this.$route.params.id + "/"), {
        id:
          new Date().getTime().toString(16) +
          Math.floor(1000 * Math.random()).toString(16),
        name: this.uname,
        memo: this.memo,
      }).then((res) => {
        console.log(res);
      });
      this.memo = "";
    },
    dragList(event, kind, dragName, dragMemo) {
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.dropEffect = "move";
      let num =
        new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16);
      this.dragItem = { kind: kind, dragId: num, dragName: dragName, dragMemo: dragMemo };
    },
    touchStartEvent: function (event, kind, dragName, dragMemo) {
      event.preventDefault();
      let num =
        new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16);
      this.dragItem = { kind: kind, dragId: num, dragName: dragName, dragMemo: dragMemo };
    },
    touchEndEvent: function (event) {
      console.log(event);
      let touch = event.changedTouches[0];
      let y = touch.pageY - 150;
      let x = touch.pageX - 400;
      const db = getDatabase();
      push(ref(db, "thinkData/" + this.$route.params.id + "/"), {
        id: this.dragItem.dragId,
        name: this.dragItem.dragName,
        memo: this.dragItem.dragMemo,
        x: x,
        y: y,
        link: [],
      }).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>

<style scoped>
.memo-input {
  width: 90%;
  position: absolute;
  bottom: 10px;
  padding: 0.5em 0.8em;
  border: solid 2px #ffccac;
  border-radius: 5px;
}
</style>
