<template>
  <div class="header w-full bg-butter-light border-b-2 border-butterscotch-default py-2 px-4">
		<div>Candy</div>
	</div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
    };
  },
  methods: {}
};
</script>

<style scoped>
.header{
  height: 50px;
}
</style>