<template>
  <div
    class="compo"
    :class="block.class"
    :style="{
      width: gridWidth * block.width + 'px',
      top: block.y * gridWidth - gridWidth + 'px',
      left: block.x * gridWidth - gridWidth + 'px',
      display: block.display ? 'block' : 'none',
    }"
    @click="Command"
  >
    <img class="item" :src="block.img[block.disp]" />
    <div class="item">{{ block.text }}</div>
    <div v-show="block.talk" class="talk" >{{ block.talk }}</div>
  </div>
</template>

<script>
export default {
  props: {
    block: Object,
    gridWidth: Number,
  },
  name: "ImgComp",
  data() {
    return {};
  },
  methods: {
    Command(){
      this.$emit("command", this.block.click);
    },
    clickCommand() {
      if (this.block.click) {
        var self = this;
        for (let command of this.block.click) {
          self.$emit(command.method, command.arg, command.nest);
          wait(3);
        }
      }

      function sleepByPromise(sec) {
        return new Promise((resolve) => setTimeout(resolve, sec * 1000));
      }
      async function wait(sec) {
        console.log("wait " + sec.toString() + " sec right now!");

        // await句を使って、Promiseの非同期処理が完了するまで待機します。
        await sleepByPromise(sec);

        console.log("wait " + sec.toString() + " sec done!");
      }
    },
  },
};
</script>

<style scoped>
.compo {
  position: absolute;
  height: auto;
  transition: all 0.3s;
}
.item {
  position: relative;
  width: 100%;
  object-fit: contain;
}
.talk{
  position: absolute;
  background: white;
  border: solid 2px gray;
  border-radius: 10px;
  top: 50%;
  padding: .3em .5em;
  max-width: 180%;
  min-width: 3em;
  overflow-wrap: break-word;
}
.talk::before,
.talk::after { /* 三角 */
  content: '';
  border: 14px solid transparent;
  position: absolute;
  left: 19px;
  margin-left: -14px;
}
.talk::before { /* 線になる部分 */
  border-bottom-color: gray; /* 吹き出しの線の色と同じ色 */
  top: -29px;
}
.talk::after { /* 地の部分 */
  border-bottom-color: #fff; /* 吹き出しの背景色と同じ色 */
  top: -27px;
}
</style>
