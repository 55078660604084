<template>
  <draggable
    @change="moved"
    class="delete-block"
    :list="list"
    item-key="id"
    group="blockList"
  >
    <template #item="{ element }">
      <li>{{ element }}</li>
    </template>
  </draggable>
  <div class="icon"><fas icon="trash-alt" /></div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "delete-block-component",
  components: {
    draggable,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    nameList: {
      get() {
        return this.$store.state.nameList;
      },
      set(value) {
        this.$store.commit("updateNameList", value);
      },
    },
  },
  methods: {
    moved: function (element) {
      let index = this.nameList.findIndex((value) => value.id == element.id);
      this.nameList.splice(index, 1);
      this.list = [];
    },
  },
};
</script>

<style scoped>
.delete-block {
  position: relative;
  background: white;
  border: 2px gray solid;
  height: 100px;
  background: #e9e9e9;
  position: relative;
}
.icon {
  position: relative;
  left: 45%;
  margin-top: -90px;
  font-size: 50px;
  color: #fff;
}
</style>
