<template>
  <div class="content" :class="{'block-list-hide': open}">
    <div class="block-list">
      <block-list :routine="routine" />
    </div>

    <div class="edit">
      <div class="tab-space">
        <div class="toggle" @click="toggle()">
          <div class="icon"><fas :icon="toggleIcon" /></div>
        </div>
        <select class="select-tab" v-model="routine">
          <option value="make">作る</option>
          <option value="main">プログラム</option>
          <option v-for="list in nameList" :key="list.id" :value="list.id">
            {{ list.name }}をクリックした時
          </option>
        </select>
      </div>

      <make-block v-show="routine == 'make'" />
      <edit-block v-show="routine == 'main'" />
      <edit-component-block :id="routine" v-if="routine != 'main' && routine != 'make'" />
    </div>

    <div class="stage">
      <stage ref="stage" />
      <delete-block-component />
    </div>
  </div>
</template>

<script>
import Stage from "@/components/Stage.vue";
import MakeBlock from "@/components/MakeBlock.vue";
import EditBlock from "@/components/EditBlock.vue";
import EditComponentBlock from "@/components/EditComponentBlock.vue";
import BlockList from "@/components/BlockList.vue";
import DeleteBlockComponent from "@/components/DeleteBlockComponent.vue";

export default {
  name: "Test",
  components: {
    Stage,
    MakeBlock,
    EditBlock,
    EditComponentBlock,
    BlockList,
    DeleteBlockComponent,
  },
  computed: {
    nameList: {
      get() {
        return this.$store.state.nameList;
      },
      set(value) {
        this.$store.commit("updateNameList", value);
      },
    },
  },

  data() {
    return {
      routine: "make",
      open: false,
      toggleIcon: "angle-double-left",
    };
  },
  methods: {
    toggle: function(){
      this.open = !this.open;
      if(this.open){
        this.toggleIcon = "angle-double-right";
      }else{
        this.toggleIcon = "angle-double-left";
      }
      var self = this;
      console.log("test")
      setTimeout(self.$refs.stage.getWidth, 100);
    }
  },
};
</script>

<style scoped>
.content {
  display: grid;
  grid-template-columns: minmax(300px, 300px) minmax(400px, 30%) minmax(300px, 70%);
  transition: all .2s ease;
}
.block-list-hide{
  grid-template-columns: minmax(0px, 0px) minmax(400px, 30%) minmax(300px, 70%);
}
.tab-space {
  background: #fff0f0;
  text-align: right;
  height: 35px;
}
.select-tab {
  background: #f6f6f6;
  margin: 0.2em 0.5em 0;
  padding: 0.2em 0.8em;
  border-radius: 8px 8px 0 0;
  position: relative;
  top: 2px;
  box-shadow: 3px -1px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid gray;
}
.block-list {
  position: relative;
  background: white;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  transition: all .2s ease;
}
.toggle {
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  margin-top: 2px;
  border: solid 2px gray;
  border-radius: 5px;
  margin-left: -5px;
  text-align: center;
  font-size: 1.2em;
}
</style>
