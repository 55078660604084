<template>
  <div class="component" :class="block.class" @click="Command">
    <div class="icon"><fas :icon="icon[block.method]" /></div>
    <div class="command">
      <select
        :value="block.arg[1]"
        @change="$emit('changeVal', block.id, $event.target.value, 1)"
      >
        <option v-for="nameList in nameList" :key="nameList.id" :value="nameList.id">
          {{ nameList.name }}
        </option>
      </select>
      を
      <select @change="$emit('changeVal', block.id, $event.target.value, 'method')">
        <option value="moveUp">上</option>
        <option value="moveDown">下</option>
        <option value="moveLeft">左</option>
        <option value="moveRight">右</option>
      </select>
      に<select @change="$emit('changeVal', block.id, Number($event.target.value), 2)">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option></select
      >マス動かす
    </div>
  </div>
</template>

<script>
//{ id: '030', block: "move1", method: "moveUp", arg: [3, 0, 1] }, 秒、対象、
export default {
  props: {
    block: Object,
    nameList: Object
  },
  data() {
    return {
      icon: {
        moveUp: "arrow-alt-circle-up",
        moveDown: "arrow-alt-circle-down",
        moveLeft: "arrow-alt-circle-left",
        moveRight: "arrow-alt-circle-right",
      },
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon {
  width: 50px;
  height: 50px;
  background: #b9daff;
  font-size: 30px;
  text-align: center;
}
</style>
