<template>
  <div
    class="component"
    :class="block.class"
    @click="Command"
  >
    <div class="icon"><fas :icon="icon[block.method]" /></div>
    <div class="command">
      <select>
        <option>上</option>
        <option>下</option>
        <option>左</option>
        <option>右</option></select
      >
      に<select>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option></select
      >マス動かす
  </div>
  </div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
  data() {
    return {
      icon: {
        moveUp: "arrow-alt-circle-up",
        moveDown: "arrow-alt-circle-down",
        moveLeft: "arrow-alt-circle-left",
        moveRight: "arrow-alt-circle-right",
        }
    };
  },
  methods: {
    Command(){
      this.$emit("command", this.block.click);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 95%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon{
  width: 50px;
  height: 50px;
  background: #b9daff;
  font-size: 30px;
  text-align: center;
}
</style>
