<template>
  <draggable
    class="drag"
    tag="ul"
    :list="blockList"
    item-key="id"
    :group="{ name: 'blockList', pull: true, put: true }"
    @change="moved"
  >
    <template #item="{ element }">
      <li class="nest">
        <component
          :is="element.block"
          :block="element"
          :nameList="nameList"
          @changeVal="changeVal"
        >
        </component>
      </li>
    </template>
  </draggable>
</template>

<script>
import Display from "@/components/EditBlock/DispBlockComponent.vue";
import Create from "@/components/EditBlock/CreateBlockComponent.vue";
import Move1 from "@/components/EditBlock/Move1BlockComponent.vue";
import Move from "@/components/EditBlock/MoveBlockComponent.vue";
import Talk from "@/components/EditBlock/TalkBlockComponent.vue";
import Repeat from "@/components/EditBlock/RepeatBlockComponent.vue";
import If from "@/components/EditBlock/IfBlockComponent.vue";
import Wait from "@/components/EditBlock/WaitBlockComponent.vue";
import Variable from "@/components/EditBlock/VariableBlockComponent.vue";
import draggable from "vuedraggable";

export default {
  name: "draggable-block-component",
  props: {
    blockList: Array,
    nameList: Array,
  },
  components: {
    draggable,
    Display,
    Create,
    Move1,
    Move,
    Talk,
    Repeat,
    If,
    Wait,
    Variable
  },
  computed: {
  },
  data() {
    return {
      err: "",
    };
  },
  methods: {
    changeVal: function (id, val, prop) {
      this.$emit("changeVal", id, val, prop);
    },
    moved: function(e){
      console.log(e.added.element)
      if(e.added.element.id && e.added.element.block == "create"){
        console.log("No Add")
        this.$emit("notAdd", e.added.element.id);
      }
    }
  },
};
</script>

<style scoped>
#block-edit {
  position: relative;
  background: white;
  border: 2px gray solid;
  height: calc(100vh - 50px);
  overflow: scroll;
}
.err {
  position: fixed;
  top: 50%;
  left: 20%;
  width: 60%;
  background: #ec4a4a;
  color: white;
  padding: 2em;
  transition: all 0.3s ease;
  text-align: center;
}
.nest {
  position: relative;
  left: 25px;
}
.drag {
  min-height: 50px;
}
</style>
