<template>
  <div class="repeat-block">
    <div class="icon"><fas icon="redo-alt" /></div>
    <div class="component" :class="block.class">
      <div class="command">
        <input type="text" />
        <span>回</span><br />
        <span>繰り返す</span>
      </div>
    </div>

    <draggable-block-component
      :blockList="block.list"
      :nameList="nameList"
      @changeVal="changeVal"
    />
    <div class="component end" :class="block.class">
      <div class="command">おわり</div>
    </div>
  </div>
</template>

<script>
//{ id: '050', block: "repeat", method: "repeat", arg: [1, 0, ""], list: [] }, 秒、対象、回数
import { defineAsyncComponent } from "vue";
import DraggableBlockComponent from "@/components/DraggableBlockComponent.vue";

export default {
  components: {
    DraggableBlockComponent: defineAsyncComponent(() =>
      Promise.resolve(DraggableBlockComponent)
    ),
  },
  props: {
    block: Object,
    nameList: Object
  },
  data() {
    return {};
  },
  methods: {
    changeVal: function (id, val, prop) {
      var ID;
      if (Array.isArray(id)) {
        console.log("array!");
        ID = id;
      } else {
        console.log("noArray");
        ID = [id];
      }
      ID.push(this.block.id);
      console.log(ID);
      this.$emit("changeVal", ID, val, prop);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  margin: 5px;
  display: flex;
}
.end {
  min-height: 25px;
}
.icon {
  min-width: 50px;
  width: 50px;
  height: 100%;
  background: #ffdfb5;
  outline: white solid 2px;
  font-size: 30px;
  text-align: center;
  position: absolute;
  left: 5px;
}
.command {
  margin-left: 50px;
}

.nest {
  margin-left: 25px;
}
</style>
