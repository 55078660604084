<template>
  <div class="component" :class="block.class" @click="Command">
    <div class="icon"><fas :icon="icon[block.component]" /></div>
    <div class="command">
      <select>
        <option>文字や数字</option>
        <option>絵</option></select
      >を入れる
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
  data() {
    return {
      icon: {ImgComp: "image", StringComp: "keyboard"}
    };
  },
  methods: {
    Command() {
      this.$emit("command", this.block.click);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 95%;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon {
  width: 50px;
  background: #addbbf;
  font-size: 30px;
  text-align: center;
}
</style>
