<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Idea',
  components: {
  },
  mounted: function(){
    var h = screen.height;
    window.open('http://localhost:8080/idea', 'idea', 'width=' + screen.width + ', height=' + h + ',left=0,top=0');
  }
}
</script>
