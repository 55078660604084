import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import { initializeApp } from 'firebase/app';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas);

const firebaseConfig = {
  apiKey: "AIzaSyDgtCu1MuXL2WDSlUyQwsiWKJt3pT0VBPA",
  authDomain: "candy-de0aa.firebaseapp.com",
  projectId: "candy-de0aa",
  storageBucket: "candy-de0aa.appspot.com",
  messagingSenderId: "573580485729",
  appId: "1:573580485729:web:25d5e66a465f204860f620",
  measurementId: "G-FR104NJP4T",
  databaseURL: "https://candy-de0aa-default-rtdb.firebaseio.com/",
};

const firebase = initializeApp(firebaseConfig);
window.firebase = firebase;

createApp(App).component('fas', FontAwesomeIcon).use(store).use(router).mount('#app')
