<template>
  <div id="block-list">
    <draggable
      v-model="blockList"
      item-key="id"
      :group="{ name: 'blockList', pull: 'clone', put: false }"
      :clone="cloneBlock"
      :sort="false"
    >
      <template #item="{ element }">
        <component :is="element.block" :block="element"></component>
      </template>
    </draggable>
  </div>
</template>

<script>
import Display from "@/components/BlockList/DispBlockComponent.vue";
import Create from "@/components/BlockList/CreateBlockComponent.vue";
import Move1 from "@/components/BlockList/Move1BlockComponent.vue";
import Move from "@/components/BlockList/MoveBlockComponent.vue";
import Talk from "@/components/BlockList/TalkBlockComponent.vue";
import Repeat from "@/components/BlockList/RepeatBlockComponent.vue";
import If from "@/components/BlockList/IfBlockComponent.vue";
import Wait from "@/components/BlockList/WaitBlockComponent.vue";
import Variable from "@/components/BlockList/VariableBlockComponent.vue";
import draggable from "vuedraggable";
import { useStore } from "vuex";

export default {
  name: "EditBlock",
  props: {
    routine: String,
  },
  components: {
    draggable,
    Display,
    Create,
    Move1,
    Move,
    Talk,
    Repeat,
    If,
    Wait,
    Variable,
  },
  setup() {
    const store = useStore();

    return {
      modeChange: (e) => store.commit("modeChange", e),
    };
  },
  data() {
    return {
      width: 0,
      editList: [
        { id: "011", block: "variable", method: "variable", arg: [1, 0, ""], list: [] },
        { id: "020", block: "display", method: "display", arg: [2, 0, 1] },
        { id: "030", block: "move1", method: "moveUp", arg: [3, 0, 1] },
        { id: "031", block: "move", method: "move", arg: [3, 0, 5, 3] },
        { id: "040", block: "talk", method: "talk", arg: [1, 0, ""] },
        { id: "050", block: "repeat", method: "repeat", arg: [1, 0, ""], list: [] },
        { id: "051", block: "if", method: "if", arg: [1, 0, ""], list: [] },
        { id: "060", block: "wait", method: "wait", arg: [1, 0, ""], list: [] },
      ],
      makeList: [
        {
          id: "010",
          block: "create",
          method: "create",
          component: "StringComp",
          name: "",
          img: ["image/logo.png"],
          text: "",
          random: false,
          randomStart: 0,
          randomEnd: 10,
          disp: 0,
          width: 1,
          x: 1,
          y: 1,
          display: 1,
          talk: "",
          click: [],
        },
      ],
    };
  },
  computed: {
    blockList: function () {
      if (this.routine == "make") {
        return this.makeList;
      } else {
        return this.editList;
      }
    },
  },
  methods: {
    cloneBlock({
      id,
      block,
      method,
      component,
      name,
      random,
      randomStart,
      randomEnd,
      img,
      text,
      disp,
      width,
      x,
      y,
      display,
      talk,
      click,
    }) {
      var check = this.blockList.find((value) => value.id == id);
      console.log(check);
      return {
        id:
          new Date().getTime().toString(16) +
          Math.floor(1000 * Math.random()).toString(16),
        block: block,
        method: method,
        component: component,
        name: name,
        img: img,
        text: text,
        disp: disp,
        random: random,
        randomStart, randomEnd,
        width: width,
        x: x,
        y: y,
        display: display,
        talk: talk,
        click: click,
        arg: [3, 0, 0, 0],
        list: [],
      };
    },
  },
};
</script>

