<template>
  <div
    class="component"
    :class="block.class"
    @click="Command"
  >
    <div class="icon"><fas icon="arrows-alt" /></div>
    <div class="command">
      <select
        :value="block.arg[1]"
        @change="$emit('changeVal', block.id, $event.target.value, 1)"
      >
        <option v-for="nameList in nameList" :key="nameList.id" :value="nameList.id">
          {{ nameList.name }}
        </option>
      </select>
      を
      <label>よこ
      <select :value="block.arg[2]"
        @change="$emit('changeVal', block.id, Number($event.target.value), 2)">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option></select
      ></label>
      <label>たて
      <select :value="block.arg[3]"
        @change="$emit('changeVal', block.id, Number($event.target.value), 3)">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option></select
      ></label>
      に動かす
  </div>
  </div>
</template>

<script>
// { id: '031', block: "move", method: "move", arg: [3, 0, 5, 3] }, 秒、対象、x、ｙ

export default {
  props: {
    block: Object,
    nameList: Object
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon{
  width: 50px;
  height: 50px;
  background: #98c8ff;
  font-size: 30px;
  text-align: center;
}
</style>
