<template>
  <div id="block-edit">
    <draggable-block-component
      :blockList="makeList"
      :nameList="nameList"
      @changeVal="changeVal"
    />
    {{ makeList }}
    <div v-show="err" class="err">{{err}}</div>
  </div>
</template>

<script>
import DraggableBlockComponent from "@/components/DraggableBlockComponent.vue";

export default {
  name: "edit-block",
  components: {
    DraggableBlockComponent,
  },
  computed: {
    makeList: {
      get() {
        return this.$store.state.makeList;
      },
      set(value) {
        this.$store.commit("updateMakeList", value);
      },
    },
    nameList: {
      get() {
        return this.$store.state.nameList;
      },
      set(value) {
        this.$store.commit("updateNameList", value);
      },
    },
  },
  data() {
    return {
      err: "",
    };
  },
  methods: {
    changeVal: function (id, val, prop) {
      let check;
      if (Array.isArray(id)) {
        check = this.makeList.find((value) => value.id == id[id.length-1]);
        for (var i = id.length - 2; i >= 0; i--) {
          check = check.list.find((value) => value.id == id[i]);
        }
      } else {
        check = this.makeList.find((value) => value.id == id);
      }
      console.log(check);
      if (isFinite(prop)) {
        check.arg[prop] = val;
      } else {
        if (prop == "name") {
          if (this.nameList.some((value) => value.name == val)) {
            this.err = "同じ名前はつけられません";
            var self = this;
            setTimeout(function () {
              self.err = "";
            }, 4000);
            check[prop] = "";
            this.nameList.find((value) => value.id == id).name = "";
            return;
          }
          if (this.nameList.find((value) => value.id == id)) {
            this.nameList.find((value) => value.id == id).name = val;
          } else {
            this.nameList.push({
              id: id,
              name: val,
            });
            check[prop] = val;
          }
        } else {
          check[prop] = val;
        }
      }
    },

    sleep: function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    doWhile: async function (arg, nest) {
      for (var i = 0; i < arg[1]; i++) {
        console.log(nest);
        for (var int = 0; int < nest.length; int++) {
          console.log(nest[int].method);
          this[nest[int].method](nest[int].arg, nest[int].nest);
          await this.sleep(nest[int].arg[0] * 100);
        }
      }
    },
    talk: function (arg) {
      console.log(arg);
      var num = arg[1];
      var text = arg[2];
      this.content.body[num].talk = text;
    },
    display: function (arg) {
      console.log("display");
      var num = arg[1];
      var int = arg[2];
      this.content.body[num].display = int;
    },
    move: function (arg) {
      console.log("moveUp");
      var num = arg[1];
      var x = arg[2];
      var y = arg[3];
      this.content.body[num].x = x;
      this.content.body[num].y = y;
    },
    moveUp: function (arg) {
      console.log("moveUp");
      var num = arg[1];
      var int = arg[2];
      var y = this.content.body[num].y;
      if (y > 0) {
        this.content.body[num].y -= int;
      }
    },
    moveDown: function (arg) {
      console.log("moveDown");
      var num = arg[1];
      var int = arg[2];
      var y = this.content.body[num].y;
      if (y > 0) {
        this.content.body[num].y += int;
      }
    },
    moveLeft: function (arg) {
      console.log("moveLeft");
      var num = arg[1];
      var int = arg[2];
      var x = this.content.body[num].x;
      if (x > 0) {
        this.content.body[num].x -= int;
      }
    },
    moveRight: function (arg) {
      console.log("moveRight");
      var num = arg[1];
      var int = arg[2];
      var x = this.content.body[num].x;
      if (x > 0) {
        this.content.body[num].x += int;
      }
    },
    changeImage: function (arg) {
      var num = arg[1];
      var int = arg[2];
      this.content.body[num].disp = int;
    },
  },
};
</script>

<style scoped>
#block-edit {
  position: relative;
  background: white;
  border: 2px gray solid;
  height: calc(100vh - 50px);
  overflow: scroll;
}
.err {
  position: fixed;
  top: 50%;
  left: 20%;
  width: 60%;
  background: #ec4a4a;
  color: white;
  padding: 2em;
  transition: all 0.3s ease;
  text-align: center;
}
.nest {
  position: relative;
  left: 25px;
}
</style>
