<template>
  <g>
    <marker id="maerker1" markerWidth="10" markerHeight="8" refX="3" refY="4">
      <path d="M 0 0 L 3 5 L 8 1 z" fill="#e74c3c" />
    </marker>
    <path
      :d="lines"
      stroke-width="5"
      fill="none"
      stroke="#e74c3c"
      marker-end="url(#maerker1)"
    ></path>
  </g>
</template>

<script>
export default {
  name: "ConnectLine",
  props: {
    start: String,
    end: String,
  },
  data() {
    return {
      connect: false,
    };
  },
  computed: {
    thinkList: {
      get() {
        return this.$store.state.thinkList;
      },
      set(value) {
        this.$store.commit("updateThinkList", value);
      },
    },
    lines: function () {
      let startItem = this.thinkList.find((val) => val.id == this.start);
      let endItem = this.thinkList.find((val) => val.id == this.end);
      let cx = startItem.x;
      let cx2 = startItem.x - 150;
      let cy = startItem.y + 100;
      let cy2 = startItem.y + 250;
      let ex = endItem.x + 100;
      let ex2 = endItem.x + 170;
      let ey = endItem.y - 5;
      let ey2 = endItem.y - 200;
      return (
        "M " +
        cx +
        " " +
        cy +
        " " +
        "C " +
        cx2 +
        " " +
        cy2 +
        "," +
        ex2 +
        " " +
        ey2 +
        "," +
        ex +
        " " +
        ey
      );
    },
  },
  methods: {
    touchMoveEvent: function (event, dragId) {
      var draggedElem = event.target;
      var touch = event.changedTouches[0];
      let idea = this.thinkList.find((val) => val.id == dragId);
      idea.y = touch.pageY - window.pageYOffset - draggedElem.offsetHeight / 2;
      idea.x = touch.pageX - window.pageXOffset - draggedElem.offsetWidth / 2;
    },
    dragList: function (event, kind, dragId) {
      this.dragItem = { kind: kind, dragId: dragId, x: event.offsetX, y: event.offsetY };
    },
    dragMove: function (event, dragId) {
      const x = event.clientX;
      const y = event.clientY;
      const dragX = this.dragItem.x;
      const dragY = this.dragItem.y;
      let idea = this.thinkList.find((val) => val.id == dragId);
      idea.x = x - dragX;
      idea.y = y - dragY;
    },
    dropList: function (event, touch) {
      if (touch == "touch" || this.connect) {
        return;
      }
      const kind = this.dragItem.kind;
      const dragId = this.dragItem.dragId;
      const x = event.clientX;
      const y = event.clientY;

      if (kind == "memo") {
        const dragId = this.dragItem.dragId;
        const dragName = this.dragItem.dragName;
        const dragMemo = this.dragItem.dragMemo;
        let memo = { id: dragId, name: dragName, memo: dragMemo, x: x, y: y };
        this.thinkList.push(memo);
      } else if (kind == "idea") {
        const dragX = this.dragItem.x;
        const dragY = this.dragItem.y;
        let idea = this.thinkList.find((val) => val.id == dragId);
        idea.x = x - dragX;
        idea.y = y - dragY;
      }
    },

    connectStart: function (connectId) {
      console.log(connectId);
      this.connect = true;
    },
    connectEnd: function () {
      this.connect = false;
    },
    connectDrop: function (event, ideaId) {
      if (this.connect) {
        console.log(ideaId);
      }
    },
  },
};
</script>

<style scoped>
.idea {
  width: 200px;
  height: 100px;
  border: solid gray 3px;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  line-height: 100px;
  background: white;
}
.memo {
  overflow: hidden;
  max-height: 100%;
}
.arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffccac;
  position: absolute;
  right: -25px;
  top: -25px;
}
</style>
