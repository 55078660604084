<template>
  <div>
    <Idea />
    <Memo />
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Memo from "@/components/Memo.vue";
import Idea from "@/components/Idea.vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    Memo,
    Idea
  },
  setup() {
    const store = useStore();
    return {
      uid: computed(() => store.state.uid),
      uname: computed(() => store.state.uname),
    };
  },
  beforeCreate() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        this.$store.commit("updateUid", user.uid);
        this.$store.commit("updateUname", user.email.substr(0, user.email.indexOf('@')));
        this.$store.commit("updateRole", user.email.substr(0, 2));
      } else {
        this.$store.commit("updateUid", "");
        this.$store.commit("updateUname", "");
                this.$router.push("/");
      }
    });
  }
};
</script>
