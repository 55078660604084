<template>
  <div class="component" :class="block.class">
    <div class="icon"><fas :icon="icon[block.arg[2]]" /></div>
    <div class="command">
      <select>
        <option>表示する</option>
        <option>隠す</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
  data() {
    return {
      icon: ["eye-slash", "eye"],
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 95%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon {
  width: 50px;
  height: 50px;
  background: #e2c6e9;
  font-size: 30px;
  text-align: center;
}
</style>
