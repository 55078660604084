<template>
  <div class="component" :class="block.class">
    <div class="icon"><fas :icon="icon[block.arg[2]]" /></div>
    <div class="command">
      <select :value="block.arg[1]" @change="$emit('changeVal', block.id, $event.target.value, 1)">
        <option v-for="nameList in nameList" :key="nameList.id" :value="nameList.id">{{ nameList.name }}</option>
      </select>
      を
      <select :value="block.arg[2]" @change="$emit('changeVal', block.id, Number($event.target.value), 2)">
        <option value="1">表示する</option>
        <option value="0">隠す</option>
      </select>
    </div>
  </div>
</template>

<script>
//{ id: '020', block: "display", method: "display", arg: [2, 0, 1] }, 秒、対象、1表示・0非表示
export default {
  props: {
    block: Object,
    nameList: Object,
  },
  data() {
    return {
      icon: ["eye-slash", "eye"],
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon {
  width: 50px;
  height: 50px;
  background: #e2c6e9;
  font-size: 30px;
  text-align: center;
}
</style>
