<template>
  <div class="container">
    <h2>ログイン</h2>
    <label
      >ユーザー名
      <input type="text" placeholder="ユーザー名" v-model="username" />
    </label>
    <label
      >パスワード
      <input type="password" placeholder="パスワード" v-model="password" />
    </label>
    <button @click="login">ログイン</button>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      username: "",
      password: "",
    };
  },
  beforeCreate() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        this.$store.commit("updateUid", user.uid);
        this.$store.commit("updateUname", user.email);
        this.$store.commit("updateRole", user.email.substr(0, 2));
        this.$router.push("/home");
      } else {
        this.$store.commit("updateUid", "");
        this.$store.commit("updateUname", "");
      }
    });
  },
  methods: {
    login: function () {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.username, this.password)
        .then((userCredential) => {
          console.log(userCredential);
          this.$router.push("/home");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.container {
  width: 400px;
  margin: 2em auto;
}
h2 {
  text-align: center;
  margin-bottom: 3em;
}
input {
  width: 100%;
  padding: 0.5em 0.2em;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 2em;
}
button {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.5em 0.8em;
}
</style>
