import { createStore } from 'vuex'

export default createStore({
  state: {
    uid: "",
    uname: "",
    role: "",
    ideaList: [],
    thinkList: [],
    nameList: [],
    dragItem: {},
    makeList: [
      {
        id: 1,
        block: "create",
        method: "create",
        component: "StringComp",
        name: "",
        img: ["image/logo.png"],
        text: "",
        random: false,
        randomStart: 0,
        randomEnd: 10,
        disp: 0,
        width: 1,
        x: 1,
        y: 1,
        display: 1,
        talk: "",
        click: [],
        arg: [3,0,0,0],
        list: []
      },
    ],
    editList:[],
  },
  mutations: {
    updateIdeaList(state, ideaList) {
      state.ideaList = ideaList;
    },
    updateThinkList(state, thinkList) {
      state.thinkList = thinkList;
    },
    updateMakeList(state, makeList) {
      state.makeList = makeList;
    },
    updateEditList(state, editList) {
      state.editList = editList;
    },
    updateNameList(state, nameList) {
      state.nameList = nameList;
    },
    updateDragItem(state, dragItem) {
      state.dragItem = dragItem;
    },
    updateUid(state, uid) {
      state.uid = uid;
    },
    updateUname(state, uname) {
      state.uname = uname;
    },
    updateRole(state, role) {
      state.role = role;
    }
  },
  actions: {
    updateIdeaList(context, ideaList) {
      context.commit('updateIdeaList', ideaList)
    },
    updateThinkList(context, thinkList) {
      context.commit('updateThinkList', thinkList)
    },
    updateMakeList(context, makeList) {
      context.commit('updateMakeList', makeList)
    },
    updateEditList(context, editList) {
      context.commit('updateEditList', editList)
    },
    updateNameList(context, nameList) {
      context.commit('updateNameList', nameList)
    },
    updateDragItem(context, dragItem) {
      context.commit('updateDragItem', dragItem)
    },
    updateUid(context, uid) {
      context.commit('updateUid', uid)
    },
    updateUname(context, uname) {
      context.commit('updateUname', uname)
    },
    updateRole(context, role) {
      context.commit('updateRole', role)
    }
  },
  modules: {
  }
})
