<template>
  <div
    class="component"
    :class="block.class"
    @click="Command"
  >
    <div class="icon"><fas icon="pause-circle" /></div>
    <div class="command">
      <input class="number-input" type="number" max="99" />
      秒待つ
  </div>
  </div>
</template>

<script>
//{ id: '060', block: "wait", method: "wait", arg: [1, 0, ""], list: [] },秒、対象
export default {
  props: {
    block: Object,
  },
  data() {
    return {
    };
  },
  methods: {
    Command(){
      this.$emit("command", this.block.click);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon{
  width: 50px;
  height: 50px;
  background: #ffba60;
  font-size: 30px;
  text-align: center;
  color: #fff8ef;
}
.number-input{
  width: 3em;
  border-bottom: solid 1px #888888;
}
</style>
