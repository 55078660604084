<template>
  <div class="component" :class="block.class" @click="Command">
    <div class="icon"><fas :icon="icon[block.component]" /></div>
    <div class="command">
      <select
        :value="block.component"
        @change="$emit('changeVal', block.id, $event.target.value, 'component')"
      >
        <option value="ImgComp">絵</option>
        <option value="StringComp">文字や数字</option>
        <option value="Variable">変数</option></select
      >を作る
      <input v-show="block.component == 'ImgComp'" type="file" accept="image/*" />
      <input
        :value="block.text"
        v-show="block.random == false && block.component == 'StringComp' || block.random == false && block.component == 'Variable'"
        type="text"
        @change="$emit('changeVal', block.id, $event.target.value, 'text')"
      />
      <label v-show="block.component == 'StringComp' || block.component == 'Variable'">乱数
        <input :checked="block.random" type="checkbox" @change="$emit('changeVal', block.id, $event.target.checked, 'random')"/>
      </label>
      <div v-show="block.random == true && block.component == 'StringComp' || block.random == true && block.component == 'Variable'">
        <input :value="block.randomStart" class="number-input" type="number" max="99" />
        から
        <input :value="block.randomEnd" class="number-input" type="number" max="99" />
        までの乱数
      </div>

      <br />
      <label
        >名前<input
          :value="block.name"
          type="text"
          @change="$emit('changeVal', block.id, $event.target.value, 'name')"
      /></label>
      <br />

      <div v-show="block.component == 'ImgComp' || block.component =='StringComp'">
        <label
          >はば<select
            :value="block.width"
            @change="$emit('changeVal', block.id, Number($event.target.value), 'width')"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
          </select></label
        >
        <label
          >横<select
            :value="block.x"
            @change="$emit('changeVal', block.id, Number($event.target.value), 'x')"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
          </select></label
        >
        <label
          >縦<select
            :value="block.y"
            @change="$emit('changeVal', block.id, Number($event.target.value), 'y')"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
          </select></label
        >
      </div>
    </div>
  </div>
</template>

<script>
//{id: '010',block: "create",method: "create",component: "StringComp",name: "",img: ["image/logo.png"],
//text: "",random: false,randomStart: 0, randomEnd: 10, disp: 0,width: 1,x: 1,y: 1,display: 1,talk: "",}
//component:"ImgComp"or"StringComp" img:画像URL disp:複数画像が指定されていた場合の表示する画像の配列番号
//text:文字表示時のテキスト,変数の中身 random:変数時の乱数かどうか
//Width:要素の幅マス目の数で指定 x、y:横、縦の位置 display:1表示・0非表示 talk:吹き出しに文字を表示

export default {
  props: {
    block: Object,
  },
  data() {
    return {
      icon: { ImgComp: "image", StringComp: "keyboard", Variable: "archive" },
    };
  },
  methods: {
    Command() {
      this.$emit("command", this.block.click);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 70%;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon {
  width: 50px;
  min-width: 50px;
  background: #addbbf;
  font-size: 30px;
  text-align: center;
}
input[type="text"] {
  border-bottom: solid 1px #888888;
  margin-bottom: 5px;
}
.number-input{
  border-bottom: solid 1px #888888;
  width: 3em;
}
</style>
