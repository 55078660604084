<template>
  <div class="home">
    <div>{{ uname }}さん</div>
    <div v-if="role == 'te'">
      <button @click="isNewProject = !isNewProject">プロジェクト新規作成</button>
      <div v-show="isNewProject" class="mt-6">
        <input type="text" v-model="projectName" placeholder="プロジェクト名" />
        <button :disabled="isDisable" @click="newProject">作成する</button>
      </div>
    </div>
    <div>
      <div class="card" v-for="(data, index) in data" :key="index">
        <a :href="'/idea/' + data.project_id">{{ data.project_name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { getDatabase, ref, push, onValue } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "Home",
  setup() {
    const store = useStore();
    return {
      uid: computed(() => store.state.uid),
      uname: computed(() => store.state.uname),
      role: computed(() => store.state.role),
    };
  },
  data() {
    return {
      isDiable: false,
      isNewProject: false,
      projectName: "",
      data: [],
    };
  },
  beforeCreate() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        this.$store.commit("updateUid", user.uid);
        this.$store.commit("updateUname", user.email.substr(0, user.email.indexOf('@')));
        this.$store.commit("updateRole", user.email.substr(0, 2));
      } else {
        this.$store.commit("updateUid", "");
        this.$store.commit("updateUname", "");
                this.$router.push("/");
      }
    });
  },
  mounted() {
    const db = getDatabase();
    const projectRef = ref(db, "/project");
    onValue(projectRef, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      this.data = data;
    });
  },
  methods: {
    newProject: function () {
      this.isDiable = true;
      console.log(this.isDiable);
      this.isDiable = false;
      const db = getDatabase();
      push(ref(db, "project/"), {
        project_name: this.projectName,
        project_id:
          new Date().getTime().toString(16) +
          Math.floor(1000 * Math.random()).toString(16),
      }).then((res) => {
        console.log(res);
        this.isDiable = true;
        this.isNewProject = false;
        this.projectName = "";
      });
    },
  },
};
</script>

<style scoped>
button {
  padding: 0.2em 0.5em;
  border: 1px gray solid;
  border-radius: 5px;
}
input {
  width: 10em;
  padding: 0.5em 0.8em;
  border: solid 2px gray;
  border-radius: 5px;
}
.home {
  margin: 1em;
}
.card{
  padding: .5em;
  border: solid 2px gray;
  border-radius: 5px;
  float: left;
  margin: 5px;
}
</style>
