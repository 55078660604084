<template>
  <div class="content-height sm:w-2/4 md:w-3/4 float-right">
    <div class="h-full w-full overflow-scroll">
      <svg
        width="100%"
        height="100%"
        @drop="dropList($event)"
        @dragover.prevent
        @dragenter.prevent
        @touchend="dropList($event, 'touch')"
        @mousemove="connectMove($event)"
        @mouseup="connectEnd($event)"
        @mouseleave="connectEnd($event)"
      >
        <connect-line
          v-for="(link, index) in links"
          :key="index"
          :start="link.start"
          :end="link.end"
        ></connect-line>

        <g v-for="idea in thinkList" :key="idea.id">
          <text
            :x="idea.x + 20"
            :y="idea.y + 60"
            font-weight="bold"
            font-size="20"
            fill="#000"
            draggable="false"
          >
            {{ idea.memo.substr(0, 10) }}
          </text>
          <rect
            :x="idea.x"
            :y="idea.y"
            width="200"
            height="100"
            rx="5"
            ry="5"
            stroke="#808080"
            fill="transparent"
            @mousedown="dragList($event, 'idea', idea.id)"
            @mousemove="dragMove($event, idea.id)"
            @mouseup="dragEnd($event, idea.id)"
            @mouseleave="dragEnd()"
            @touchstart.prevent
            @touchmove="touchMoveEvent($event, idea.id)"
            @drop="connectDrop($event, idea.id)"
          />
          <!--
          <circle style="fill: #ffccac" :cx="idea.x" :cy="idea.y + 100" r="25" />
          <rect
            :x="idea.x"
            :y="idea.y + 85"
            style="fill: #ffffff"
            width="18"
            height="12"
          />
          <rect
            :x="idea.x - 18"
            :y="idea.y + 104"
            style="fill: #ffffff"
            width="18"
            height="12"
          />
          <line
            :x1="idea.x + 9"
            :y1="idea.y + 91"
            :x2="idea.x - 9"
            :y2="idea.y + 110"
            stroke="#fff"
            stroke-width="2"
          />
          <circle
            style="fill: transparent"
            :cx="idea.x"
            :cy="idea.y + 100"
            r="25"
            @mousedown="connectStart($event, idea.id)"
            @mouseup="connectEnd()"
            @touchstart.prevent
            @touchmove="touchMoveEvent($event, idea.id)"
          />
          -->
        </g>
        <line
          :x1="connectLine.x1"
          :y1="connectLine.y1"
          :x2="connectLine.x2"
          :y2="connectLine.y2"
          :stroke="connectLine.stroke"
          stroke-width="3"
        />
      </svg>
      {{ thinkList }}
    </div>
  </div>
</template>

<script>
import { getDatabase, ref, push, update, onValue } from "firebase/database";
import ConnectLine from "@/components/ConnectLine.vue";
export default {
  components: {
    ConnectLine,
  },
  data() {
    return {
      drag: false,
      connect: false,
      connectId: "",
      links: [],
      connectLine: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        stroke: "rgb(252, 112, 112, .0)",
      },
    };
  },
  computed: {
    dragItem: {
      get() {
        return this.$store.state.dragItem;
      },
      set(value) {
        this.$store.commit("updateDragItem", value);
      },
    },
    thinkList: {
      get() {
        return this.$store.state.thinkList;
      },
      set(value) {
        this.$store.commit("updateThinkList", value);
      },
    },
  },
  mounted() {
    const db = getDatabase();
    const thinkRef = ref(db, "/thinkData/" + this.$route.params.id + "/");
    onValue(thinkRef, (snapshot) => {
      const thinkdata = snapshot.val();
      console.log(thinkdata);
      this.thinkList = thinkdata;
    });
  },
  methods: {
    touchMoveEvent: function (event, dragId) {
      var touch = event.changedTouches[0];
      let idea = Object.values(this.thinkList).find((val) => val.id == dragId);
      idea.y = touch.pageY - window.pageYOffset - 150;
      idea.x = touch.pageX - window.pageXOffset - 400;
      const dragName = idea.name;
      const dragMemo = idea.memo;
      const db = getDatabase();
      const dataKey = Object.keys(this.thinkList).filter((key) => {
        return this.thinkList[key] == idea;
      });
      update(ref(db, "thinkData/" + this.$route.params.id + "/" + dataKey[0]), {
        id: dragId,
        name: dragName,
        memo: dragMemo,
        x: idea.x,
        y: idea.y,
        link: [],
      }).then((res) => {
        console.log(res);
      });
    },
    dragList: function (event, kind, dragId) {
      this.dragItem = { kind: kind, dragId: dragId };
      this.drag = true;
    },
    dragMove: function (event, dragId) {
      if (this.drag) {
        const x = event.movementX;
        const y = event.movementY;
        let idea = Object.values(this.thinkList).find((val) => val.id == dragId);
        const dragX = idea.x;
        const dragY = idea.y;
        const dragName = idea.name;
        const dragMemo = idea.memo;
        idea.x = x + dragX;
        idea.y = y + dragY;
        const db = getDatabase();
        const dataKey = Object.keys(this.thinkList).filter((key) => {
          return this.thinkList[key] == idea;
        });
        update(ref(db, "thinkData/" + this.$route.params.id + "/" + dataKey[0]), {
          id: dragId,
          name: dragName,
          memo: dragMemo,
          x: idea.x,
          y: idea.y,
          link: [],
        }).then((res) => {
          console.log(res);
        });
      }
    },
    dragEnd: function (event, ideaId) {
      this.drag = false;
      if (this.connect && ideaId) {
        this.connectDrop(event, ideaId);
      }
    },
    dropList: function (event, touch) {
      if (touch == "touch" || this.connect) {
        return;
      }
      const kind = this.dragItem.kind;
      if (kind == "idea") {
        return;
      }
      const dragId = this.dragItem.dragId;
      const x = event.layerX - 100;
      const y = event.layerY - 50;

      if (kind == "memo") {
        const dragName = this.dragItem.dragName;
        const dragMemo = this.dragItem.dragMemo;
        const db = getDatabase();
        push(ref(db, "thinkData/" + this.$route.params.id + "/"), {
          id: dragId,
          name: dragName,
          memo: dragMemo,
          x: x,
          y: y,
          link: [],
        }).then((res) => {
          console.log(res);
        });
      }
    },

    connectStart: function (event, connectId) {
      console.log(event.target.cx.baseVal.value);
      this.connectId = connectId;
      this.connect = true;
      this.connectLine.x1 = event.target.cx.baseVal.value;
      this.connectLine.y1 = event.target.cy.baseVal.value;
      this.connectLine.x2 = event.target.cx.baseVal.value;
      this.connectLine.y2 = event.target.cy.baseVal.value;
      this.connectLine.stroke = "rgb(252, 112, 112, .5)";
    },
    connectMove: function (event) {
      if (this.connect) {
        this.connectLine.x2 += event.movementX;
        this.connectLine.y2 += event.movementY;
      }
    },
    connectEnd: function () {
      this.connect = false;
      this.connectLine.x1 = 0;
      this.connectLine.y1 = 0;
      this.connectLine.x2 = 0;
      this.connectLine.y2 = 0;
      this.connectLine.stroke = "rgb(252, 112, 112, 0.0)";
    },
    connectDrop: function (event, ideaId) {
      if (this.connect && this.connectId != ideaId) {
        const idea = this.links.filter((val) => val.start == this.connectId);
        if (!idea.length) {
          let item = {
            start: this.connectId,
            end: ideaId,
          };
          this.links.push(item);
        } else {
          let end = idea.find((value) => value.end == ideaId);
          if (!end) {
            let item = {
              start: this.connectId,
              end: ideaId,
            };
            this.links.push(item);
          }
        }
      }
      this.connect = false;
    },
  },
};
</script>

<style scoped>
.idea {
  width: 200px;
  height: 100px;
  border: solid #808080 3px;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  line-height: 100px;
  background: white;
}
.memo {
  overflow: hidden;
  max-height: 100%;
}
.arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffccac;
  position: absolute;
  left: -25px;
  bottom: -25px;
}
.icon {
  width: 50px;
  height: 50px;
  color: white;
  font-size: 25px;
  margin-top: -25px;
}
</style>
