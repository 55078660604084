<template>
  <div
    class="component"
    :class="block.class"
    @click="Command"
  >
    <div class="icon"><fas icon="arrows-alt" /></div>
    <div class="command">
      <label>よこ
      <select>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option></select
      ></label>
      <label>たて
      <select>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option></select
      ></label>
      に動かす
  </div>
  </div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
  data() {
    return {
    };
  },
  methods: {
    Command(){
      this.$emit("command", this.block.click);
    },
  },
};
</script>

<style scoped>
.component {
  position: relative;
  min-height: 50px;
  width: 95%;
  height: auto;
  transition: all 0.3s;
  border: gray solid 2px;
  outline: white solid 2px;
  margin: 5px;
  display: flex;
}
.icon{
  width: 50px;
  height: 50px;
  background: #98c8ff;
  font-size: 30px;
  text-align: center;
}
</style>
